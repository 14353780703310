/* Basic animation for the icons */
@keyframes iconBounce {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  .animated-icon {
    animation: iconBounce 2s ease-in-out infinite;
  }

  /* about us page */
  
 /* styles.css */

body {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
}

.about-container {
  padding: 20px;
}

.hero-section {
  background: url('../assets/images/hero-bg.jpg') no-repeat center center/cover;
  color: #fff;
  padding: 60px 20px;
  text-align: center;
}

.hero-content h1 {
  font-size: 2.5rem;
  margin: 0;
}

.hero-content p {
  font-size: 1.2rem;
}

.mission-section, .values-section, .cta-section {
  padding: 40px 20px;
  margin: 20px 0;
  text-align: center;
}

h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.values-section ul {
  list-style-type: none;
  padding: 0;
}

.values-section li {
  margin: 10px 0;
  font-size: 1.1rem;
}

.cta-section {
  background-color: #1976d2;
  color: white;
  padding: 40px 20px;
}

.cta-button {
  display: inline-block;
  background-color: #ffcc00;
  color: #1976d2;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
}

.cta-button:hover {
  background-color: #ffb300;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .hero-content h1 {
    font-size: 2rem;
  }

  .hero-content p {
    font-size: 1rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .cta-button {
    font-size: 1rem;
    padding: 8px 16px;
  }
  /* styles.css */

/* Add this section or adjust the existing section for the team section */
.team-section {
  margin: 40px 0;
  text-align: center;
}

.team-section h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.team-member img {
  width: 100px; /* Set the width of the images if using regular <img> tags */
  height: 100px; /* Set the height of the images */
  border-radius: 50%; /* Make images circular */
  object-fit: cover; /* Maintain aspect ratio */
}

}


/* Values Section */
.values-section {
  background-color: white;
  padding: 40px;
  text-align: center;
}

.values-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.values-content ul {
  list-style: none;
  padding: 0;
}

.values-content ul li {
  font-size: 1.2rem;
  margin: 10px 0;
  color: #444;
}

.values-content ul li strong {
  color: #6c63ff;
}

/* Team Section */
.team-section {
  padding: 40px;
  text-align: center;
  background-color: #f9f9f9;
}

.team-section h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

.team-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.team-member {
  max-width: 250px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.team-member img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  margin-bottom: 10px;
}

.team-member h3 {
  font-size: 1.5rem;
  margin: 10px 0 5px;
}

.team-member p {
  font-size: 1rem;
  color: #777;
}

/* Call to Action (CTA) Section */
.cta-section {
  background-color: #6c63ff;
  color: white;
  padding: 50px;
  text-align: center;
}

.cta-content h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.cta-content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #fff;
  color: #6c63ff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e6e6ff;
}
